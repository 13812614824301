<template>
  <Modal v-model="showConfirm" width="500" title="批量设置媒体价格" footer-hide :mask-closable="false">
      <Row class="m-b-10">
        <i-col span="4" class="p-t-5">标价</i-col>
        <i-col span="8">
          <InputNumber v-model="inventoryBean.originalPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
        </i-col>
        <i-col span="4" class="p-t-5">初始价格</i-col>
        <i-col span="8">
          <InputNumber v-model="inventoryBean.sellingPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
        </i-col>
      </Row>
      <Row class="m-b-10">
        <i-col span="4" class="p-t-5">最低限价</i-col>
        <i-col span="8">
          <InputNumber v-model="inventoryBean.statementPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的最低限价"></InputNumber>
        </i-col>
        <i-col span="12" class="p-t-5">
          <Checkbox v-model="inventoryBean.dynamic">开启动态定价</Checkbox>
        </i-col>
      </Row>

      <Row class="m-b-10">
        <i-col span="20" offset="4">
          <p class="remark">tips: 此设置的价格为默认每个档期/规格的售价，若要单独设置，请从列表中编辑单个媒体</p>
        </i-col>
      </Row>

      <Row>
        <i-col span="20" offset="4">
          <i-button class="m-t-10" type="primary" @click="handleSubmit(false)">保存设置</i-button>
          <i-button class="m-t-10 m-l-10" type="success" @click="handleSubmit(true)">提交并投放</i-button>
        </i-col>
      </Row>

      <Spin fix size="large" v-if="spinShow">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>数据保存中...</div>
      </Spin>
  </Modal>
</template>

<script>
import { updateSchedulePrice } from '@/api/product/cloudinventory'

export default {
  data () {
    return {
      showConfirm: false,
      spinShow: false,

      inventoryBean: {
        dynamic: false, // 是否开启动态定价
        originalPrice: 0.01,
        sellingPrice: 0.01,
        statementPrice: 0.01, // 结算价
        inventoryIds: [], // 批量商品ID
        isDeliver: false // 是否投放
      }
    }
  },
  methods: {
    showModal (inventoryIds) {
      this.showConfirm = true
      this.inventoryBean.inventoryIds = inventoryIds
    },
    handleSubmit (isDeliver) {
      this.spinShow = true
      this.inventoryBean.isDeliver = isDeliver
      const successMsg = isDeliver ? '并成功投放，请注意查看商品投放状态！' : ''

      updateSchedulePrice(this.inventoryBean).then(res => {
        this.$Notice.success({ desc: '批量设置商品价格成功！' + successMsg })
        this.spinShow = false
        this.showConfirm = false
        this.$emit('on-success')
      })
    }
  }
}
</script>
